import styled, { css } from 'styled-components'
import {
  space,
  color,
  typography,
  ColorProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system'
import { Statuses } from '@src/interfaces'
import { Token, Color } from '@revolut/ui-kit'
import { IdStatuses } from '@src/interfaces/employees'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { QueryStatuses } from '@src/interfaces/dataAnalytics'
import { InterviewFeedbackStatus, InterviewStatuses } from '@src/interfaces/interviewTool'

export const getStatusColor = (
  status:
    | Statuses
    | IdStatuses
    | ApprovalStatuses
    | InterviewStatuses
    | InterviewFeedbackStatus
    | QueryStatuses
    | undefined,
) => {
  return getStatusThemeColor(status)
}

export const getStatusThemeColor = (
  status?:
    | Statuses
    | IdStatuses
    | ApprovalStatuses
    | InterviewStatuses
    | InterviewFeedbackStatus
    | QueryStatuses,
): Color => {
  switch (status) {
    case Statuses.opened:
    case Statuses.open:
      return Token.color.teal

    case Statuses.preapproved:
    case Statuses.funcapproved:
    case Statuses.extended:
    case Statuses.applied:
      return Token.color.yellow_70

    case Statuses.on_track:
    case Statuses.active:
    case Statuses.accepted:
    case Statuses.approved:
    case Statuses.completed:
    case Statuses.complete:
    case Statuses.ongoing:
    case Statuses.linked:
      return Token.color.success

    case Statuses.pending:
    case Statuses.pending_approval:
    case Statuses.pending_reapproval:
    case Statuses.pending_completion:
    case Statuses.planned:
    case IdStatuses.onboarding:
    case Statuses.requires_changes:
    case Statuses.at_risk:
    case InterviewStatuses.pending_scheduling:
    case InterviewStatuses.pending_candidate_response:
    case InterviewStatuses.awaiting_interviewer_availability:
    case InterviewStatuses.awaiting_feedback:
    case InterviewStatuses.pending_cv_screening:
    case InterviewFeedbackStatus.pending:
    case InterviewFeedbackStatus.draft:
      return Token.color.warning

    case Statuses.closed:
    case Statuses.revoked:
    case Statuses.rejected:
    case Statuses.cancelled:
    case Statuses.canceled:
    case Statuses.terminated:
    case Statuses.delayed:
    case InterviewStatuses.interviewer_rejected:
    case InterviewStatuses.scheduling_expired:
    case InterviewStatuses.interview_cancelled:
    case InterviewFeedbackStatus.rejected:
      return Token.color.danger

    case Statuses.archived:
    case Statuses.expired:
    case Statuses.inactive:
    case Statuses.draft:
    case Statuses.future:
      return Token.color.grey20

    case Statuses.unassigned:
      return Token.color.greyTone50

    default:
      return Token.color.foreground
  }
}

export const Status = styled.span<{
  status: Statuses | IdStatuses | ApprovalStatuses
  forceColor?: Color
}>`
  text-transform: capitalize;
  color: ${({ status, forceColor }) => forceColor || getStatusColor(status)};
`

export const noScrollBar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const A = styled.a.attrs(props =>
  props.target === '_blank' ? { rel: 'noreferrer noopener' } : undefined,
)<{
  disabled?: boolean
}>`
  align-self: center;
  color: ${Token.color.blue};
  text-decoration: none;
  pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};
  opacity: ${props => (props.disabled ? 0.3 : 1)};

  &:hover {
    text-decoration: underline;
  }
`

export const Capitalize = styled.span`
  text-transform: capitalize;
`

export const ColoredText = styled.div<{ color: string }>`
  display: flex;
  color: ${props => props.color};
  width: 100%;
  justify-content: start;
`

export const StyledOL = styled.ol<
  SpaceProps & ColorProps & TypographyProps & { indicatorColor?: Color }
>`
  padding-inline-start: 1rem;

  li::marker {
    color: ${props => (props.indicatorColor ? props.indicatorColor : 'inherit')};
  }

  ${space}
  ${color}
  ${typography}
`

export const StyledUL = styled.ul<
  SpaceProps & ColorProps & TypographyProps & { indicatorColor?: Color }
>`
  padding-inline-start: 1rem;

  li::marker {
    color: ${props => (props.indicatorColor ? props.indicatorColor : 'inherit')};
  }

  ${space}
  ${color}
  ${typography}
`

export const StyledLI = styled.li<SpaceProps & ColorProps & TypographyProps>`
  ${space}
  ${color}
  ${typography}
`
